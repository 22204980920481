import React, { useContext } from "react";
import { UIContext } from './UIContext';
import styled from "styled-components";
import CategoryTag from "./CategoryTag";

const StyledBlogCategoryList = styled.section`
  min-width: 170px;
  
  .shy-heading {
    margin-top: 2vmin;
    font-family: -apple-system, Arial, "Helvetica", "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
      sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--foreground);
    display: inline-block;
    margin-bottom: 1rem;
  }

  .shy-heading:after {
    content: "...";
  }

  .list-categories {
    list-style-type: none;
    & > li {
      margin: 0rem 0.3rem 0.4rem 0rem;
    }
  }

  @media all and (max-width: 540px) {
    .list-categories {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

// Translation object
const translations = {
  categories: {
    en: "Categories",
    de: "Kategorien",
    da: "Kategorier",
    se: "Kategorier",
    no: "Kategorier",
    nl: "Categorieën"
  }
};

const BlogCategoryList = props => {
  const uicontext = useContext(UIContext)
  return (
    <StyledBlogCategoryList className="blog-categories">
      <div className="cards-header">
        <h2 className="shy-heading">
          {translations.categories[uicontext.language]}
        </h2>
      </div>
      <ul className="list-categories">
        {props.data.map(tag => (
          <CategoryTag tag={tag} key={tag} />
        ))}
      </ul>
    </StyledBlogCategoryList>
  );
}

export default React.memo(BlogCategoryList);
