import React, { useContext } from 'react';
import { UIContext } from './UIContext';
import RelatedContentCardFlexBig from "../components/RelatedContentCardFlexBig";
import styled from "styled-components";

const StyledCardContainer = styled.div`
  width: 75%;
  margin-right: 1rem;

  @media all and (max-width: 700px) {
    max-width: 60%;
    .cards {
      min-width: 50%;
    }
  }

  @media all and (max-width: 540px) {
    order: 2;
    width: 100%;
    max-width: unset;

    .cards {
      min-width: 50%;
      flex-direction: column;
    }
  }

  .cards-header {
    margin-top: 2vmin;
    display: flex;
    justify-content: space-between;

    .shy-heading {
      font-family: -apple-system, Arial, "Helvetica", "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", sans-serif;
      font-size: 1.2rem;
      font-weight: bold;
      color: var(--foreground);
      display: inline-block;
      white-space: nowrap;
      margin-bottom: 1rem;
    }

    .shy-heading:after {
      content: "...";
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }
`;

const translations = {
  blogPosts: {
    en: "Blog posts",
    de: "Blogbeiträge",
    da: "Blogindlæg",
    se: "Blogginlägg",
    no: "Blogginnlegg",
    nl: "Blog berichten"
  }
};


function CardContainer(props) {
  const uicontext = useContext(UIContext) // Destructured for direct access
  return (
    <StyledCardContainer>
      <div className="cards-header">
        <h1 className="shy-heading">
          {translations.blogPosts[uicontext.language]}
        </h1>
      </div>
      <div className="cards">
        {props.data.allMdx &&
          props.data.allMdx.edges.map(({ node: post }) => (
            <RelatedContentCardFlexBig
              key={post.id}
              imageName={post.frontmatter.image.name}
              relativePath={post.frontmatter.image.relativePath}
              title={post.frontmatter.title}
              link={`/${post.frontmatter.language + post.fields.slug}`}
              language={post.frontmatter.language}
              description={post.frontmatter.description}
              date={post.frontmatter.publish}
              timeToRead={post.timeToRead}
              tags={
                post.frontmatter.tags &&
                post.frontmatter.tags.split(",").map(function (item) {
                  item = item.split(":")[0];
                  return item.trim();
                })
              }
            />
          ))}
      </div>
    </StyledCardContainer>
  );
}

export default React.memo(CardContainer);
